import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createElement } from 'react';
import { withData } from '../../../../../hoc/withData';
import styles from './style.m.less';
import classNames from 'classnames';
import { preventJavascriptUrls } from '../../../../../../services/security';
const defaultRenderer = d => d.link;
export default function RichLink({ resolve = {}, render, renderPending, renderError, getIcon, isErrorState, data }) {
    const wrapperProps = {
        icon: getIcon(data),
        link: data.link,
    };
    const mainComponent = props => {
        const error = isErrorState ? isErrorState(props) : false;
        const propsWithError = Object.assign(Object.assign({}, wrapperProps), { error });
        return _jsx(Wrapper, Object.assign({}, propsWithError, { children: render(props) }));
    };
    const body = resolve
        ? withData({
            resolve,
            pendingComponent: () => {
                const r = renderPending || defaultRenderer;
                return _jsx(Wrapper, Object.assign({}, wrapperProps, { children: r(data) }));
            },
            errorComponent: ({ error }) => {
                const r = renderError || defaultRenderer;
                const propsWithError = Object.assign(Object.assign({}, data), { error });
                const wrapperPropsWithError = Object.assign(Object.assign({}, wrapperProps), { error });
                return _jsx(Wrapper, Object.assign({}, wrapperPropsWithError, { children: r(propsWithError) }));
            },
        })(mainComponent)
        : mainComponent;
    return createElement(body, data);
}
function Wrapper(props) {
    const { children, error, icon, link } = props;
    const className = classNames(styles.content, {
        [styles.error]: !!error,
    });
    const mainProps = {
        className,
        title: link,
        href: preventJavascriptUrls(link),
        target: '_blank',
    };
    return (_jsxs("a", Object.assign({}, mainProps, { children: [icon, " ", children] })));
}
