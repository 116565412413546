import { handleActions } from 'redux-actions';
import { createAction } from 'redux-actions';
const SLICE_NAME = 'headerSearchBar';
const UPDATE_QUERY = 'header-search-bar/UPDATE_QUERY';
const SEARCH = 'header-search-bar/SEARCH';
const SEARCH_SUCCESS = 'header-search-bar/SEARCH_SUCCESS';
const SEARCH_FAIL = 'header-search-bar/SEARCH_FAIL';
const SHOW_RESULTS = 'header-search-bar/SHOW_RESULTS';
const HIDE_RESULTS = 'header-search-bar/HIDE_RESULTS';
const CLEAR_RESULTS = 'header-search-bar/CLEAR_RESULTS';
const OPEN_SEARCH = 'header-search-bar/OPEN_SEARCH';
const SELECT_ITEM = 'header-search-bar/SELECT_ITEM';
const SELECT_NEXT_ITEM = 'header-search-bar/SELECT_NEXT_ITEM';
const SELECT_PREV_ITEM = 'header-search-bar/SELECT_PREV_ITEM';
const initialState = {
    mode: 'TEAM',
    query: '',
    result: [],
    typeOfResult: null,
    status: 'LOADING',
    showResults: false,
    shouldScrollTo: false,
    inputVisible: false,
    shouldShowLoader: true,
    selectedItem: null,
};
function doClearResult(query) {
    return createAction(CLEAR_RESULTS)({ query });
}
function applyClearResult(state) {
    return Object.assign(Object.assign({}, state), { result: [], status: 'LOADED' });
}
function doUpdateQuery(query) {
    return createAction(UPDATE_QUERY)({ query });
}
function applyUpdateQuery(state, action) {
    return Object.assign(Object.assign({}, state), { query: action.payload.query, status: 'LOADING', selectedItem: null, showResults: true, inputVisible: true, mode: action.payload.query === '' ? 'TEAM' : 'SEARCH', shouldShowLoader: shouldShowLoader(state) });
}
function shouldShowLoader(state) {
    return !(state.typeOfResult === 'SEARCH' && state.result.length > 0);
}
function doSearch(query) {
    return createAction(SEARCH)({ query });
}
function doSearchSuccess(query, result) {
    return createAction(SEARCH_SUCCESS)({ query, result });
}
function doSearchError(query, error) {
    return createAction(SEARCH_FAIL)({ query, error });
}
function applySearch(state, action) {
    if (action.payload.query.type === 'SEARCH') {
        return Object.assign(Object.assign({}, state), { status: 'LOADING', mode: action.payload.query.type });
    }
    else {
        return Object.assign(Object.assign({}, state), { status: 'LOADING', shouldShowLoader: true, mode: action.payload.query.type });
    }
}
function applySearchSuccess(state, action) {
    const { result } = action.payload;
    return Object.assign(Object.assign({}, state), { result: result.type === state.mode ? result.data : state.result, status: 'LOADED', typeOfResult: result.type });
}
function applySearchFail(state) {
    return Object.assign(Object.assign({}, state), { status: 'ERROR' });
}
function doShowResults() {
    return createAction(SHOW_RESULTS)();
}
function applyShowResults(state) {
    return Object.assign(Object.assign({}, state), { showResults: true, inputVisible: true, selectedItem: state.inputVisible ? state.selectedItem : null });
}
function doHideResults() {
    return createAction(HIDE_RESULTS)();
}
function applyHideResults(state) {
    return Object.assign(Object.assign({}, state), { showResults: false, status: 'LOADED', inputVisible: false, query: '' });
}
function doOpenSearch() {
    return createAction(OPEN_SEARCH)();
}
function applyOpenSearch(state) {
    return Object.assign(Object.assign({}, state), { showResults: false, status: 'LOADED', inputVisible: true });
}
function doSelectItem(id, preventScroll = false) {
    return createAction(SELECT_ITEM)({ id, preventScroll });
}
function applySelectItem(state, action) {
    return Object.assign(Object.assign({}, state), { selectedItem: action.payload.id, preventScroll: action.payload.preventScroll });
}
function doSelectNextItem() {
    return createAction(SELECT_NEXT_ITEM)();
}
function applySelectNextItem(state) {
    return Object.assign(Object.assign({}, state), { selectedItem: state.selectedItem === null ? 0 : state.selectedItem + 1, preventScroll: false });
}
function doSelectPrevItem() {
    return createAction(SELECT_PREV_ITEM)();
}
function applySelectPrevItem(state) {
    return Object.assign(Object.assign({}, state), { selectedItem: state.selectedItem === null ? -1 : state.selectedItem - 1, preventScroll: false });
}
const reducer = handleActions({
    [UPDATE_QUERY]: applyUpdateQuery,
    [SEARCH]: applySearch,
    [SEARCH_SUCCESS]: applySearchSuccess,
    [SEARCH_FAIL]: applySearchFail,
    [SHOW_RESULTS]: applyShowResults,
    [HIDE_RESULTS]: applyHideResults,
    [CLEAR_RESULTS]: applyClearResult,
    [OPEN_SEARCH]: applyOpenSearch,
    [SELECT_ITEM]: applySelectItem,
    [SELECT_PREV_ITEM]: applySelectPrevItem,
    [SELECT_NEXT_ITEM]: applySelectNextItem,
}, initialState);
const reducers = { [SLICE_NAME]: reducer };
const actionCreators = {
    doClearResult,
    doUpdateQuery,
    doSearch,
    doSearchSuccess,
    doSearchError,
    doShowResults,
    doHideResults,
    doOpenSearch,
    doSelectItem,
    doSelectNextItem,
    doSelectPrevItem,
};
export { reducers, actionCreators };
