import { jsx as _jsx } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import { IconBadgeSuccess, IconBadgeFail } from 'featherico';
function IconValidation({ isValid }) {
    return isValid ? _jsx(IconBadgeSuccess, {}) : _jsx(IconBadgeFail, {});
}
IconValidation.propTypes = {
    isValid: PropTypes.bool,
};
export default IconValidation;
