import { createElement } from 'react';
import InflectionService from '../../../../../common/services/inflection/service';
import getReplacement from '../../../../../react/services/Replacement';
export { transformSentence, resolveReplacements };
function transformSentence(text, values) {
    text = resolveReplacements(text);
    text = InflectionService.fixArticle(text);
    return replace(text, values);
}
function resolveReplacements(template) {
    const pattern = /:(.*?):/i;
    const match = template.match(pattern);
    if (!match) {
        return template;
    }
    const replaceable = match[0];
    const replacementKey = match[1];
    return template.replace(replaceable, getReplacement(replacementKey));
}
function replace(template, replacements) {
    const pattern = /(?:(.*?){{(.*?)}}|(.+))/g;
    const result = [];
    let count = 0;
    template.replace(pattern, function (match, group1, placeholder, group3) {
        if (group1) {
            result.push(createElement('span', { key: count++ }, group1));
        }
        if (placeholder) {
            const value = replacements[placeholder] || '';
            result.push(getReplacedDomNode(value, count++));
        }
        if (group3) {
            result.push(createElement('span', { key: count++ }, group3));
        }
        return 'some string';
    });
    return result;
}
function getReplacedDomNode(value, key) {
    return createElement('span', { key }, value);
}
