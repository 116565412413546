import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import { IconBook } from 'featherico';
import Link from '../Link';
import * as React from 'react';
function DocumentationLink({ href, title, text }) {
    return (_jsxs(Link, { openInNewTab: true, href: href, title: title, className: "documentation-link button", children: [_jsx(IconBook, {}), text || ' Documentation'] }));
}
DocumentationLink.propTypes = {
    href: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    text: PropTypes.string,
};
export default DocumentationLink;
