import { reduce } from 'lodash/fp';
// interface Feature {
//   name: string,
//   propType: PropTypes,
//   defaultProp: T,
//   onParse?: (htmlContent: string, args: T) => string
//   onMount?: (rootNode: HTMLElement, args: T) => void
// }
import * as ensureHtml from './features/ensureHtml';
import * as canonicalize from './features/canonicalize';
import * as linkShortener from './features/linkShortener';
import * as richLinks from './features/richLinks';
import * as images from './features/images';
import * as mentions from './features/mentions';
import * as personalize from './features/personalize';
import * as openLinksInExternalTab from './features/openLinksInExternalTab';
import * as sanitize from './features/sanitize';
import * as highlight from './features/highlight';
const registry = [
    ensureHtml,
    canonicalize,
    linkShortener,
    richLinks,
    images,
    mentions,
    personalize,
    openLinksInExternalTab,
    sanitize,
    highlight,
];
export function getFeatures() {
    return reduce((mem, feature) => {
        const { name, defaultProp, propType, onParse, onMount } = feature;
        mem.defaultProps[name] = defaultProp;
        mem.propTypes[name] = propType;
        mem.callbacks[name] = { onParse, onMount };
        return mem;
    }, {
        defaultProps: {},
        propTypes: {},
        callbacks: {},
    }, registry);
}
