import { jsx as _jsx } from "react/jsx-runtime";
import { forEachRight, some, startsWith } from 'lodash/fp';
import NotificationAlert from '../../../PopupAlert/NotificationAlert/withProvider';
const FLUSH_DELAY = 700;
const POPUP_STAY_ALIVE_TIME = 9 * 1000;
const BLOCKED_PATHS = ['/newAdmin'];
export function create(getCurrentCount, browser, popupAlert, timeout, pathname = '', flushDelay = FLUSH_DELAY) {
    const queue = [];
    const active = !some(blockedPath => startsWith(blockedPath, pathname))(BLOCKED_PATHS);
    const enqueue = forEachRight(notification => {
        queue.push(notification);
    });
    function push(notifications) {
        if (!active) {
            return;
        }
        if (browser.isTabActive()) {
            notify(notifications);
        }
        else {
            enqueue(notifications);
        }
    }
    function init() {
        browser.addTabActivityListener(tabActive => {
            if (tabActive) {
                flush();
            }
        });
    }
    function notify(notifications = []) {
        const all = notifications.concat(takeAllFromQueue());
        if (!all.length) {
            return;
        }
        const first = all[0];
        const remainingCount = getCurrentCount() - 1;
        const timer = timeout(() => popupAlert.dismiss(), POPUP_STAY_ALIVE_TIME);
        const onUnmount = () => clearTimeout(timer);
        popupAlert.setMessage(_jsx(NotificationAlert, { item: first, remainingCount: remainingCount, onUnmount: onUnmount }));
    }
    function flush() {
        // Wait a bit so that the user can focus his eyes on the new tab before
        // something of important happens and give another request a chance to
        // potentially add even more items.
        // Also prevents flushing when the user was just browsing quickly through
        // tabs and left immediately afterwards
        timeout(() => {
            if (browser.isTabActive()) {
                notify();
            }
        }, flushDelay);
    }
    function takeAllFromQueue() {
        const items = [];
        while (queue.length) {
            items.push(queue.pop());
        }
        return items;
    }
    init();
    return { push, queue };
}
