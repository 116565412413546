import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import api from '../../../../../../api';
import { batchRequest } from '../../../../../../common/services/request-batcher';
import * as urlBuilder from '../../../../../../common/services/url-builder';
import { withData } from '../../../../../hoc/withData';
import { preventJavascriptUrls } from '../../../../../../services/security';
function NoMention({ loginname }) {
    return _jsxs("span", { children: [loginname, " "] });
}
function Mention({ user, loginname }) {
    if (!user) {
        return _jsx(NoMention, { loginname: loginname });
    }
    const { id, name } = user;
    const href = preventJavascriptUrls(urlBuilder.linkToNewUserProfile(id));
    const linkProps = {
        href,
        className: classNames('ui-mention'),
    };
    return _jsx("a", Object.assign({}, linkProps, { children: name }));
}
export default withData({
    resolve: {
        user: ({ loginname }) => batchRequest(api.usersMedium.getByLoginnames, loginname, 'loginname'),
    },
    pendingComponent: NoMention,
    errorComponent: NoMention,
})(Mention);
