import PropTypes from 'prop-types';
import { truncate } from 'lodash/fp';
const RE = /(<a.*?>)([A-Za-z]+:\/\/[-A-Z0-9+&@#/%?=~_|!:,.;*]*[-A-Z0-9+&@#/%=~_|*])(<\/a>)/gi;
export const name = 'linkShortener';
export const propType = PropTypes.shape({
    maxLength: PropTypes.number,
});
export const defaultProp = {
    maxLength: 50,
};
export const onParse = (content, { maxLength }) => {
    return content.replace(RE, (fullMatch, preTag, link, postTag) => {
        return `${preTag}${truncate({ length: maxLength }, link)}${postTag}`;
    });
};
