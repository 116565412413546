import { jsx as _jsx } from "react/jsx-runtime";
import * as uriHelper from '../../helpers/uri';
import { IconGithub } from 'featherico';
export function match(link) {
    if (!link.match('https?://(?:www.)?github.com')) {
        return null;
    }
    const schemeless = uriHelper.stripScheme(link);
    const parts = uriHelper.split(schemeless);
    const category = parts[3];
    const repoName = parts.slice(1, 3).join('/');
    switch (category) {
        case 'pulls':
            return {
                text: `${repoName}: PR ${parts[4]}`,
            };
        case 'issues':
            return {
                text: `${repoName}: Issue ${parts[4]}`,
            };
        case 'commit':
            return {
                text: `${repoName}: #${parts[4].slice(0, 7)}`,
            };
    }
    return {
        shortenedLink: parts.slice(1).join('/'),
    };
}
export function getIcon() {
    return _jsx(IconGithub, {});
}
export function render(props) {
    return props.text || props.shortenedLink;
}
