import { jsx as _jsx } from "react/jsx-runtime";
import { createMountPoint, forEachMountPoint } from '../../helper';
import PropTypes from 'prop-types';
import PlainLink from './PlainLink';
import RichLink from './RichLink';
import * as siContentItems from './matchers/siContentItems';
import * as dropbox from './matchers/dropbox';
import * as googleProducts from './matchers/googleProducts';
import * as github from './matchers/github';
import * as commonFiles from './matchers/commonFiles';
import { some, startsWith } from 'lodash/fp';
export const name = 'richLinks';
export const propType = PropTypes.bool;
export const defaultProp = true;
/* eslint-disable max-len */
const RE = /(href="|src="|data-original-link="|<a.*?>)?[A-Za-z]+:\/\/[-A-Z0-9+&@#/%?=~_|!:,.;*]*[-A-Z0-9+&@#/%=~_|*]/gi;
/* eslint-enable max-len */
const RTE_RICH_LINK = 'rte-rich-link';
const MATCHERS = [siContentItems, dropbox, googleProducts, github, commonFiles];
const isWhitelistedUrlScheme = url => some(scheme => startsWith(scheme, url), ['http', 'https', 'ftp', 'mailto']);
export function onParse(content) {
    return content.replace(RE, (fullLink, prohibitingClause) => {
        if (prohibitingClause || !isWhitelistedUrlScheme(fullLink)) {
            return fullLink;
        }
        return createMountPoint(RTE_RICH_LINK, {
            link: fullLink,
        }, fullLink);
    });
}
export function onMount(node) {
    forEachMountPoint(node, RTE_RICH_LINK, mountPointDataset => {
        const { link } = mountPointDataset;
        for (let i = 0; i < MATCHERS.length; i++) {
            const matcher = MATCHERS[i];
            const matchData = matcher.match(link);
            if (!matchData) {
                continue;
            }
            const { getIcon, render, renderPending, renderError, resolve, isErrorState } = matcher;
            const data = Object.assign({ link }, matchData);
            const props = {
                resolve: resolve ? resolve(data) : null,
                render,
                renderPending,
                renderError,
                getIcon,
                isErrorState,
                data: Object.assign({ link }, matchData),
            };
            return _jsx(RichLink, Object.assign({}, props));
        }
        return _jsx(PlainLink, { link: link });
    });
}
