import { takeEvery, takeLatest } from 'redux-saga';
import { call, fork, put, select } from 'redux-saga/effects';
import { actionTypes, actionCreators, selectors } from '../ducks';
import { push } from '../services/pushAlert';
import api from '../../../../api';
const { SHOW_NOTIFICATIONS_DROPDOWN, GET_NOTIFICATIONS, MARK_AS_READ, MARK_ALL_AS_READ, MARK_AS_UNREAD } = actionTypes;
export function* watchShowDropdown() {
    yield* takeEvery(SHOW_NOTIFICATIONS_DROPDOWN, resetCountOnOpen);
}
export function* watchMarkAsRead() {
    yield* takeEvery(MARK_AS_READ, markAsRead);
}
export function* watchGetNotifications() {
    yield* takeLatest(GET_NOTIFICATIONS, getNotifications);
}
export function* watchMarkAllAsRead() {
    yield* takeEvery(MARK_ALL_AS_READ, markAllAsRead);
}
export function* watchMarkAsUnread() {
    yield* takeEvery(MARK_AS_UNREAD, markAsUnread);
}
function* resetCountOnOpen(action) {
    const show = action.payload;
    if (!show) {
        return;
    }
    fork(checkForLatestNotifications);
    const count = yield select(selectors.getCount);
    const countObj = yield select(selectors.getCountObject);
    if (!count || !countObj) {
        return;
    }
    yield put(actionCreators.doUpdateCount(0));
    yield call(withErrorHandling(api.notificationsCount.resetCount), countObj);
}
function* markAsRead({ payload }) {
    yield call(withErrorHandling(api.notifications.markAsRead), payload);
}
function* getNotifications({ payload }) {
    const { limit, offset, pushAlert } = payload;
    const notifications = yield call(safeGetLatest(), { limit, offset });
    if (pushAlert) {
        push(notifications);
    }
    yield put(actionCreators.doAddNotifications(notifications));
}
function* markAllAsRead() {
    yield call(withErrorHandling(api.notifications.markAllAsRead));
}
function* markAsUnread({ payload }) {
    yield call(withErrorHandling(api.notifications.markAsUnread), payload);
}
function* checkForLatestNotifications() {
    const notifications = yield call(safeGetLatest(), { offset: 0, limit: 10 });
    yield put(actionCreators.doAddNotifications(notifications));
}
function safeGetLatest() {
    return withErrorHandling(api.notifications.getLatest, []);
}
function withErrorHandling(cb, defaultValue = null) {
    return (...args) => {
        return new Promise(resolve => {
            cb(...args).then(response => resolve(response), () => resolve(defaultValue));
        });
    };
}
