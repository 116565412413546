import { jsx as _jsx } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import { Component } from 'react';
import classNames from 'classnames';
import { forEach, reduce } from 'lodash';
import * as registry from './registry';
import './style.m.less';
const registeredFeatures = registry.getFeatures();
const propTypes = Object.assign({ value: PropTypes.string }, registeredFeatures.propTypes);
const defaultProps = registeredFeatures.defaultProps;
const FEATURES = registeredFeatures.callbacks;
class RichText extends Component {
    componentDidMount() {
        this.mountFeatures();
    }
    componentDidUpdate() {
        this.mountFeatures();
    }
    mountFeatures() {
        mountWithFeatures(this.rteNode, this.props);
    }
    render() {
        const { value, className, style } = this.props;
        const props = {
            className: classNames(className, 'rich-text-view'),
            style,
            dangerouslySetInnerHTML: {
                __html: parseWithFeatures(value, this.props),
            },
            ref: node => (this.rteNode = node),
        };
        return _jsx("span", Object.assign({}, props));
    }
}
RichText.propTypes = propTypes;
RichText.defaultProps = defaultProps;
export default RichText;
const IDENTITY = t => t;
export function parseWithFeatures(content, features) {
    if (!content) {
        return content;
    }
    return reduce(FEATURES, (mem, feature, name) => {
        const args = features[name];
        const fn = feature.onParse || IDENTITY;
        return args ? fn(mem, args) : mem;
    }, content);
}
function mountWithFeatures(node, features) {
    forEach(FEATURES, (feature, name) => {
        const fn = feature.onMount || IDENTITY;
        const args = features[name];
        if (args) {
            fn(node, args);
        }
    });
}
